<template>
  <div class="grid gap-2 justify-center px-6">
    <div class="mt-10 flex justify-center">
      <img
        src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
        alt="Playda Logo"
        width="161"
        height="70"
      />
    </div>
    <div class="mt-4 font-InterSemiBold text-white">
      Game Jam planning is
      <span class="text-nColorYellow font-InterExtraBold">desktop only</span>
      for now, so grab your computer and let the creative fun begin!
    </div>
    <div>
      <img
        src="https://playda.s3.ap-south-1.amazonaws.com/diy/mobile_illustration_desktop_only.webp"
        alt="Playda Desktop Only"
        width="320"
        height="320"
      />
    </div>
    <div class="font-InterSemiBold text-nColorLightGray">
      Visit
      <a
        class="text-nColorYellow italic font-bold"
        :href="marketingURL"
        target="_blank"
        >playda.io</a
      >
      for more information!
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      marketingURL: process.env.VUE_APP_MARKETING_URL,
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style>
body,
html {
  background-color: #151e23;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
